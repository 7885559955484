import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import {useTranslation} from "react-i18next";
import axios from "axios";

function Start() {
  const { t, i18n } = useTranslation();

  const downloadExport = () => {
    axios({
      url: 'https://backend.easier-integration.nuromedia.com/questionnaire/export',
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const href = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'questionnaire-export.csv');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  return (
    <div id="easier-start">
      <ReactBootstrap.Container fluid={true}>
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div className="start-form">
              <h2>
                {t('questionnaire-export')}
              </h2>
              <div className="easier-download-button" onClick={downloadExport}>
                {t('download')}
              </div>
            </div>
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>
      </ReactBootstrap.Container>
    </div>
  );
}

export default Start;
