import React, {useEffect} from "react";
import './i18n';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import * as ReactBootstrap from "react-bootstrap";
import {useTranslation} from "react-i18next";

/* Styles */
import './App.css';

/* Icons */
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/js/all.js';

/* Components */
import Header from "./components/header";
import Footer from "./components/footer";

/* Views */
import Start from "./views/start";
import Error from "./views/error";

/* node_modules / src*/
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const language = urlParams.get('language') ? urlParams.get('language') : "";

    if(language.toUpperCase() === "DE" || language.toLowerCase() === "dgs"){
      localStorage.setItem("lang", "de");
      i18n.changeLanguage("de")
    }
    else if(language.toUpperCase() === "CH" || language.toLowerCase() === "dsgs"){
      localStorage.setItem("lang", "ch");
      i18n.changeLanguage("ch")
    }
    else if(language.toUpperCase() === "EN" || language.toLowerCase() === "bsl"){
      localStorage.setItem("lang", "en");
      i18n.changeLanguage("en")
    }
    else if(language.toUpperCase() === "FR" || language.toLowerCase() === "lsf"){
      localStorage.setItem("lang", "fr");
      i18n.changeLanguage("fr");
    }
    else if(language.toUpperCase() === "GR" || language.toLowerCase() === "gsl"){
      localStorage.setItem("lang", "gr");
      i18n.changeLanguage("gr");
    }
    else if(language === "" || language === null || language === undefined){
      localStorage.setItem("lang", "en");
      i18n.changeLanguage("en");
    }
    else {
      localStorage.setItem("lang", "en");
      i18n.changeLanguage("en");
    }
  }, []);

  return (
    <div className="App">
      <Header/>
      <Router>
        <Routes>
          <Route path="/" element={<Start />} />
          <Route path="*" element={<Error />}/>
        </Routes>
      </Router>
      <Footer/>
    </div>
  );
}

export default App;
