import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import React from "react";

const resources = {
  en: {
    translation: {
      "English": "English",
      "German": "German",
      "French": "French",
      "Italian": "Italian",
      "Greek": "Greek",
      "Dutch": "Dutch",
      "Swiss-Italian": "Swiss-Italian",
      "Swiss-French": "Swiss-French",
      "Swiss-German": "Swiss-German",
      "Luxembourg-German": "Luxembourg-German",
      "Luxembourg-French": "Luxembourg-French",
      "language": "Language",
      "questionnaire-export": "Export questionnaire",
      "download": "Download",
      "error-message": "Page not found",
      "back-link": "Back to home",
    }
  },
  de: {
    translation: {
      "English": "Englisch",
      "German": "Deutsch",
      "French": "Französisch",
      "Italian": "Italienisch",
      "Greek": "Griechisch",
      "Dutch": "Niederländisch",
      "Swiss-Italian": "Schweizer-Italienisch",
      "Swiss-French": "Schweizer-Französisch",
      "Swiss-German": "Schweizer-Deutsch",
      "Luxembourg-German": "Luxemburg-Deutsch",
      "Luxembourg-French": "Luxemburg-Französisch",
      "language": "Sprache",
      "questionnaire-export": "Export Fragebogen",
      "download": "Herunterladen",
      "error-message": "Seite nicht gefunden",
      "back-link": "Zurück zur Startseite",
    }
  },
  ch: {
    translation: {
      "English": "Englisch",
      "German": "Deutsch",
      "French": "Französisch",
      "Italian": "Italienisch",
      "Greek": "Griechisch",
      "Dutch": "Niederländisch",
      "Swiss-Italian": "Schweizer-Italienisch",
      "Swiss-French": "Schweizer-Französisch",
      "Swiss-German": "Schweizer-Deutsch",
      "Luxembourg-German": "Luxemburg-Deutsch",
      "Luxembourg-French": "Luxemburg-Französisch",
      "language": "Sprache",
      "questionnaire-export": "Export Fragebogen",
      "download": "Herunterladen",
      "error-message": "Seite nicht gefunden",
      "back-link": "Zurück zur Startseite",
    }
  },
  it: {
    translation: {
      "English": "Inglese",
      "German": "Tedesco",
      "French": "Francese",
      "Italian": "Italiano",
      "Greek": "Greco",
      "Dutch": "Olandese",
      "Swiss-Italian": "Svizzero-italiano",
      "Swiss-French": "Svizzero-francese",
      "Swiss-German": "Svizzero-tedesco",
      "Luxembourg-German": "Lussemburgo-tedesco",
      "Luxembourg-French": "Lussemburgo-francese",
      "language": "Lingua",
      "questionnaire-export": "Questionario sull'esportazione",
      "download": "Scaricare",
      "error-message": "Pagina non trovata",
      "back-link": "Torna alla home",
    }
  },
  fr: {
    translation: {
      "English": "Anglais",
      "German": "Allemand",
      "French": "Français",
      "Italian": "Italienne",
      "Greek": "Grec",
      "Dutch": "Néerlandais",
      "Swiss-Italian": "Suisse-Italienne",
      "Swiss-French": "Suisse-Français",
      "Swiss-German": "Suisse-Allemand",
      "Luxembourg-German": "Luxembourg-Allemand",
      "Luxembourg-French": "Luxembourg-Français",
      "language": "Langue",
      "questionnaire-export": "Questionnaire d'exportation",
      "download": "Télécharger",
      "error-message": "Page non trouvée",
      "back-link": "Retour à l'accueil",
    }
  },
  gr: {
    translation: {
      "English": "Αγγλικά",
      "German": "Γερμανικά",
      "French": "Γαλλικά",
      "Italian": "Ιταλικά",
      "Greek": "Ελληνική",
      "Dutch": "Ολλανδικά",
      "Swiss-Italian": "Ελβετικό-ιταλικό",
      "Swiss-French": "Ελβετικό-Γαλλικά",
      "Swiss-German": "Ελβετικό-Γερμανικά",
      "Luxembourg-German": "Λουξεμβούργο-Γερμανικά",
      "Luxembourg-French": "Λουξεμβούργο-Γαλλικά",
      "language": "Γλώσσα",
      "questionnaire-export": "Ερωτηματολόγιο εξαγωγής",
      "download": "Λήψη",
      "error-message": "Η σελίδα δεν βρέθηκε",
      "back-link": "Επιστροφή στην αρχική σελίδα",
    }
  },
  nl: {
    translation: {
      "English": "Engels",
      "German": "Duits",
      "French": "Frans",
      "Italian": "Italiaans",
      "Greek": "Grieks",
      "Dutch": "Nederlands",
      "Swiss-Italian": "Zwitsers-Italiaans",
      "Swiss-French": "Zwitsers-Frans",
      "Swiss-German": "Zwitsers-Duits",
      "Luxembourg-German": "Luxemburg-Duits",
      "Luxembourg-French": "Luxemburg-Frans",
      "language": "Taal",
      "questionnaire-export": "Vragenlijst exporteren",
      "download": "Downloaden",
      "error-message": "Pagina niet gevonden",
      "back-link": "Terug naar home",
    }
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem("lang") || "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
