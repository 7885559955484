import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Error() {
  const { t, i18n } = useTranslation();

  return (
    <div id="easier-error">
      <ReactBootstrap.Container>
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <h2>
              Error - 404
            </h2>
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div>
              {t('error-message')}
            </div>
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Link to="/">
              {t('back-link')}
            </Link>
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>
      </ReactBootstrap.Container>
    </div>
  );
}

export default Error;
