import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
/* Icons - Flags */
import FlagEN from './../assets/icons/flaggreatbritain.png';
import FlagDE from './../assets/icons/flaggerman.png';
import FlagCH from './../assets/icons/flagsuizze.png';
import FlagGR from './../assets/icons/flaggreece.png';
import FlagFR from './../assets/icons/flagfrench.png';

function Header() {
  const { t, i18n } = useTranslation();
  const headerTextLong = useMediaQuery({query: '(max-width: 400px)'});
  const headerBiggerMobile = useMediaQuery({query: '(min-width: 768px)'});

  const changeLanguageGerman = () =>
  {
    i18n.changeLanguage("de")
    localStorage.setItem("lang", "de")
  }

  const changeLanguageSwissGerman = () =>
  {
    i18n.changeLanguage("ch")
    localStorage.setItem("lang", "ch")
  }

  const changeLanguageEnglish = () =>
  {
    i18n.changeLanguage("en")
    localStorage.setItem("lang", "en")
  }

  const changeLanguageItalian = () =>
  {
    i18n.changeLanguage("it")
    localStorage.setItem("lang", "it")
  }

  const changeLanguageFrench = () =>
  {
    i18n.changeLanguage("fr")
    localStorage.setItem("lang", "fr")
  }

  const changeLanguageGreek = () =>
  {
    i18n.changeLanguage("gr")
    localStorage.setItem("lang", "gr")
  }

  const changeLanguageDutch = () =>
  {
    i18n.changeLanguage("nl")
    localStorage.setItem("lang", "nl")
  }

  return (
    <div id="easier-header">
      <ReactBootstrap.Navbar className="easier-navbar-bg" expand="lg" variant="light">
        <ReactBootstrap.Container fluid>
          <ReactBootstrap.Navbar.Brand className="easier-navbar-header-title">
            {
              headerTextLong ? (
                <div className="text-left">
                  EASIER - <br/> {t('questionnaire-export')}
                </div>
              ) : (
                <>
                  EASIER - {t('questionnaire-export')}
                </>
              )
            }
          </ReactBootstrap.Navbar.Brand>
          {/*<ReactBootstrap.Navbar.Toggle aria-controls="basic-navbar-nav" />
          <ReactBootstrap.Navbar.Collapse id="basic-navbar-nav">*/}
          {/*<ReactBootstrap.Nav className="justify-content-end" style={{ width: "100%" }}>
              <ReactBootstrap.Nav.Link href="#home">Home</ReactBootstrap.Nav.Link>*/}
              <ReactBootstrap.NavDropdown title={
                <>
                  {
                    localStorage.getItem("lang") == "de" ? (
                      <span>
                        <img src={FlagDE} alt="German flag"/>
                      </span>
                    ) : localStorage.getItem("lang") == "en" ? (
                      <span>
                        <img src={FlagEN} alt="English flag"/>
                      </span>
                    ) : localStorage.getItem("lang") == "fr" ? (
                      <span>
                        <img src={FlagFR} alt="French flag"/>
                      </span>
                    ) : localStorage.getItem("lang") == "gr" ? (
                      <span>
                        <img src={FlagGR} alt="Greece flag"/>
                      </span>
                    ) : localStorage.getItem("lang") == "ch" ? (
                      <span>
                        <img src={FlagCH} alt="Swiss flag"/>
                      </span>
                    ) : (
                      <span>
                        <img src={FlagEN} alt="English flag"/>
                      </span>
                    )
                  }
                  {
                    headerBiggerMobile ? (
                      localStorage.getItem("lang") == "de" ? (
                        <>
                          Deutsch
                        </>
                      ) : localStorage.getItem("lang") == "en" ? (
                        <>
                          English
                        </>
                      ) : localStorage.getItem("lang") == "fr" ? (
                        <>
                          Français
                        </>
                      ) : localStorage.getItem("lang") == "gr" ? (
                        <>
                          Ελληνική
                        </>
                      ) : localStorage.getItem("lang") == "ch" ? (
                        <>
                          Schweizer-Deutsch
                        </>
                      ) : (
                        <>
                          English
                        </>
                      )
                    ) : (
                      <></>
                    )
                  }
                  {/*t('language')*/}
                </>
              } id="basic-nav-dropdown">
                <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageEnglish}>
                  <span>
                    <img src={FlagEN} alt="English flag"/>
                  </span>
                  {/*t('English')*/}
                  English
                </ReactBootstrap.NavDropdown.Item>
                <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageGerman}>
                  <span>
                    <img src={FlagDE} alt="German flag"/>
                  </span>
                  {/*t('German')*/}
                  Deutsch
                </ReactBootstrap.NavDropdown.Item>
                <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageFrench}>
                  <span>
                    <img src={FlagFR} alt="French flag"/>
                  </span>
                  {/*t('French')*/}
                  Français
                </ReactBootstrap.NavDropdown.Item>
                {/*<ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageItalian}>
                  {t('Italian')}
                </ReactBootstrap.NavDropdown.Item>*/}
                <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageGreek}>
                  <span>
                    <img src={FlagGR} alt="Greece flag"/>
                  </span>
                  {/*t('Greek')*/}
                  Ελληνική
                </ReactBootstrap.NavDropdown.Item>
                {/*<ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageDutch}>
                  {t('Dutch')}
                </ReactBootstrap.NavDropdown.Item>*/}
                <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageSwissGerman}>
                  <span>
                    <img src={FlagCH} alt="Swiss flag"/>
                  </span>
                  {/*t('Swiss-German')*/}
                  Schweizer-Deutsch
                </ReactBootstrap.NavDropdown.Item>
              </ReactBootstrap.NavDropdown>
          {/*</ReactBootstrap.Nav>
          </ReactBootstrap.Navbar.Collapse>*/}
        </ReactBootstrap.Container>
      </ReactBootstrap.Navbar>
    </div>
  );
}

export default Header;
