import React from "react";
import * as ReactBootstrap from "react-bootstrap";

function Footer() {
  return (
    <div id="easier-footer">
      <ReactBootstrap.Navbar className="easier-navbar-bg" expand="lg" variant="light">
        <ReactBootstrap.Container fluid>
          <ReactBootstrap.Navbar.Brand className="easier-navbar-header-title">(c) 2023 - EASIER</ReactBootstrap.Navbar.Brand>
        </ReactBootstrap.Container>
      </ReactBootstrap.Navbar>
    </div>
  );
}

export default Footer;
